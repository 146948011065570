import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';

import Layout from '@/components/Layout/Layout';

// Pages
import Dashboard from '@/pages/Dashboard/Dashboard';
import DataSimpanan from '@/pages/Simpanan/DataSimpanan';
import MutasiSimpanan from '@/pages/Simpanan/MutasiSimpanan';
import MutasiSimpananKolektor from '@/pages/Simpanan/MutasiSimpananKolektor';
import TransaksiSimpanan from '@/pages/Simpanan/TransaksiSimpanan';
import TransaksiHarianAdmin from '@/pages/Simpanan/TransaksiHarianAdmin';

import DataSimpananAdmin from '@/pages/Simpanan/DataSimpananAdmin';
import PengajuanPinjaman from '@/pages/Pinjaman/PengajuanPinjaman';

import SimulasiPinjaman from '@/pages/Pinjaman/SimulasiPinjaman';

import TransaksiPinjamanAdmin from '@/pages/Pinjaman/TransaksiPinjamanAdmin';
import DaftarPinjaman from '@/pages/Pinjaman/DaftarPinjaman';
import DaftarKreditur from '@/pages/Pinjaman/DaftarKreditur';
import SetoranKreditur from '@/pages/Pinjaman/SetoranKreditur';
import daftartagihan from '@/pages/daftartagihan';
import TransaksiHarian from '@/pages/Simpanan/TransaksiHarian';
import TransaksiHarianPinjaman from '@/pages/Pinjaman/TransaksiHarianPinjaman';
import DataKredit from '@/pages/Pinjaman/DataKredit';

import TransaksiAll from '@/pages/TransaksiAll';


import Login from "@/pages/Login/Login";

Vue.use(Router);

  const routes = [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
    path: '/',
    redirect: 'login',
    name: 'Layout',
    component: Layout,
    children: [
      {
        path: 'dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: { auth: true }
      },
      {
        path: 'datasimpanan',
        name: 'DataSimpanan',
        component: DataSimpanan,
        meta: { auth: true }
      },
      {
        path: 'mutasisimpanan',
        name: 'MutasiSimpanan',
        component: MutasiSimpanan,
        meta: { auth: true }
      },
      {
        path: 'mutasisimpanankolektor',
        name: 'MutasiSimpananKolektor',
        component: MutasiSimpananKolektor,
        meta: { auth: true }
      },
      {
        path: 'transaksisimpanan',
        name: 'TransaksiSimpanan',
        component: TransaksiSimpanan,
        meta: { auth: true }
      },
      {
        path: 'pengajuanpinjaman',
        name: 'PengajuanPinjaman',
        component: PengajuanPinjaman,
        meta: { auth: true }
      },
      {
        path: 'daftartagihan',
        name: 'daftartagihan',
        component: daftartagihan,
        meta: { auth: true }
      },

      {
        path: 'simulasipinjaman',
        name: 'SimulasiPinjaman',
        component: SimulasiPinjaman,
        meta: { auth: true }
      },
      {
        path: 'daftarpinjaman',
        name: 'DaftarPinjaman',
        component: DaftarPinjaman,
        meta: { auth: true }
      },
      {
        path: 'daftarkreditur',
        name: 'DaftarKreditur',
        component: DaftarKreditur,
        meta: { auth: true }
      },
      {
        path: 'setorankreditur',
        name: 'SetoranKreditur',
        component: SetoranKreditur,
        meta: { auth: true }
      },
      {
        path: 'transaksiharian',
        name: 'TransaksiHarian',
        component: TransaksiHarian,
        meta: { auth: true }
      },
      {
        path: 'transaksiharianpinjaman',
        name: 'TransaksiHarianPinjaman',
        component: TransaksiHarianPinjaman,
        meta: { auth: true }
      },
      {
        path: 'datasimpananadmin',
        name: 'DataSimpananAdmin',
        component: DataSimpananAdmin,
        meta: { auth: true }
      },
      {
        path: 'transaksipinjamanadmin',
        name: 'TransaksiPinjamanAdmin',
        component: TransaksiPinjamanAdmin,
        meta: { auth: true }
      },
      {
        path: 'transaksiharianadmin',
        name: 'TransaksiHarianAdmin',
        component: TransaksiHarianAdmin,
        meta: { auth: true }
      },
      {
        path: 'transaksiall',
        name: 'TransaksiAll',
        component: TransaksiAll,
        meta: { auth: true }
      },

      
      
      {
        path: 'datakredit',
        name: 'DataKredit',
        component: DataKredit,
        meta: { auth: true }
      },

      
      

      
    ],
  },
    {
      path: '*',
      name: 'Error',
      component: Error
    }
  ]

  const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
  }) 

  router.beforeEach((to, from, next) => {
    // jika routing ada meta auth-nya maka
    if (to.matched.some(record => record.meta.auth)) {
      // jika user adalah guest
      if(store.getters['auth/guest']){
            next('/login')
      }
      else{
        next()
      } 
    }
    else{
        next()
    }
  })
  
  // router.beforeEach((to, from, next) => { 
  //   if (to.matched.some(record => record.meta.auth)) {
  //     if (store.getters.isLoggedIn && store.getters.user) {
  //       next()
  //       return
  //     }
  //     next('/login')
  //   }
  
  //   if (to.matched.some(record => record.meta.guest)) {
  //     if (!store.getters.isLoggedIn) {
  //       next()
  //       return
  //     }
  //     next('/dashboard')
  //   }
  
  //   next()
  // })
  
  export default router

